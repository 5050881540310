import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemplateDynamicComponent } from './template-component/template-dynamic.component';
import { TemplateDynamicRoutingModule } from './template-dynamic-routing.module';

import { TemplateSingleModule } from '@core-mkt/shared/templates/single-template/template-st.module';
import { Template404Module } from '@core-mkt/shared/templates/template-404/template-404.module';

@NgModule({
  declarations: [TemplateDynamicComponent],
  imports: [CommonModule, TemplateDynamicRoutingModule, Template404Module, TemplateSingleModule],
})
export class TemplateDynamicModule {}
