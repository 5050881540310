import { DOCUMENT } from '@angular/common';
import type { AfterViewInit, OnInit } from '@angular/core';
import { Component, ElementRef, HostListener, Inject, Input, ViewChild } from '@angular/core';

import { CountdownService } from '@core-mkt/services/countdown/countdown.service';
import { ElementVisibilityService } from '@core-mkt/services/element-visibility/element-visibility.service';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import { PricingService } from '@core-mkt/services/pricing/pricing.service';
import { ProductService } from '@core-mkt/services/product/product.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ServerWistiaService } from '@core-mkt/services/wistia/server-wistia.service';
import { WistiaService } from '@core-mkt/services/wistia/wistia.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { XGritCompleteProduct } from '@core-mkt/services/xgrit-api/xgrit-product';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import type { VisionVideoButton } from '@core-mkt/shared/components/vision-video-button/vision-video-button/vision-video-button';
import type { Observable } from 'rxjs';
import type {
  BottomRightContent,
  CategoryWidgets,
  FeaturedContent,
  newSecondButtonData,
  VideoButton,
} from './top-section';
import { TopSection } from './top-section';

import { faAngleDown, faAngleUp, faChevronDown, faChevronUp, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Brand } from '@core-mkt/enums/brand.enum';
import { BrandConfigurationService } from '@core-mkt/services/configuration/brand-configuration.service';
import { EnvService } from '@core-mkt/services/env/env.service';
import { TrustpilotService } from '@core-mkt/services/trustpilot/trustpilot.service';
import { TrustpilotInfoText } from './trustpilot/trustpilot-data';

@Component({
  selector: 'top-section',
  templateUrl: './top-section.component.html',
  styleUrls: ['./top-section.scss'],
})
export class TopSectionComponent implements OnInit, AfterViewInit {
  @Input() data: TopSection;
  @ViewChild('topSectionCta', { static: false }) topSectionCta: ElementRef;
  faTimes = faTimes;
  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  hasContent = false;
  modalHasContent = false;
  showTitle = false;
  showContent = false;
  showModal = false;
  product: XGritCompleteProduct;
  theme: ThemeInterface;
  originalPrice: number;
  discountPrice: number;
  countdown: Observable<number>;
  offerEndTime: number = null;
  productCtaLink: string;
  pricingText: string;
  percentSaved: number;
  percentSavedText: string;
  wistiaEmbed: string = null;
  transitionTime: number;
  modalContent: string;
  wistiaCtaData: VideoButton;
  trustpilotTrustScore: number;
  trustpilotNumberOfReviews: number;
  trustpilotInfoText = TrustpilotInfoText;
  trustpilotVerified = false;
  componentClasses = '';
  private readonly brandId: string = new EnvService().get.xgritApiConfig.baseParams.brandId;
  private truspilotBrandUrl: string;
  newsecondButtonData: newSecondButtonData;
  isCoursePopupMinimized = false;
  isMobileCoursePopupMinimized = true;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    private ps: ProductService,
    private countdownService: CountdownService,
    public imgixService: ImgixService,
    private pricing: PricingService,
    private elementVisibility: ElementVisibilityService,
    private wistia: ServerWistiaService,
    private clientWistia: WistiaService,
    private tps: TrustpilotService,
    private bcs: BrandConfigurationService,
    private env: EnvService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.truspilotBrandUrl = `https://www.trustpilot.com/review/${this.bcs.url}`;
  }

  /**
   * Returns the label for the money guarantee label based on the brand ID.
   * @returns The label for the money guarantee.
   */
  get moneyGuaranteeLabel(): string {
    return this.brandId === Brand.AARP ? 'Money-Back Guarantee!**' : '100% Money Back Guarantee';
  }

  /**
   * Returns the color for the money guarantee label based on the brand ID.
   * @returns The color for the money guarantee.
   */
  get moneyGuaranteeLinkStyle(): string {
    // Note: This is a temporary text style for the AARP brand link, color could be renamed in the future
    switch (this.brandId) {
      case Brand.AARP:
      case Brand.ACE:
        return 'text-ace-teal-700 border-b-2 border-b-ace-teal-700';
      default:
        return '';
    }
  }

  get isLink(): boolean {
    return this.data.ctaLink !== null && !this.data.ctaLink.includes('#');
  }

  get imgAlign(): string {
    return this.data.contentAlignment ? 'lg:' + this.data.contentAlignment : 'lg:flex-row mx-auto';
  }

  get courseOverviewPopup(): boolean {
    return this.data?.courseOverviewPopup;
  }

  get outerButtonAlign(): string {
    const soloButtonWrapperClasses = 'flex xl:flex-row lg:flex-col md:flex-row flex-col lg:items-start';
    if (this.showFirstButtonOnly) {
      return soloButtonWrapperClasses;
    }
    if ((this.secondButtonData || this.wistiaButtonData) && !this.showModal1CTA && !this.showCTA) {
      return soloButtonWrapperClasses;
    }
    if ((this.secondButtonData || this.wistiaButtonData) && (this.showModal1CTA || this.showCTA)) {
      return soloButtonWrapperClasses + ' flex-wrap';
    }
    return '';
  }

  get ctaDisplay(): boolean {
    return this.elementVisibility.isElVisible;
  }

  /**
   * returns cta button data.
   * @returns cta button data.
   */
  get ctaButtonData(): VisionButton {
    return {
      buttonStyle: this.data.ctaType,
      bgTheme: this.componentClasses,
      buttonText: this.ctaLabel,
      buttonLink: this.ctaLink,
      fullWidth: false,
      nofollow: this.ctaNofollow,
      noreferrer: this.ctaNewTab,
      noopener: this.ctaNewTab,
      newTab: this.ctaNewTab,
      ctaTag: this.ctaTag,
    };
  }

  get wistiaButtonData(): VisionVideoButton {
    if (this.hasSecondButtonData && this.data.videoButton[0].buttonType != 'button') {
      this.wistiaCtaData = this.data.videoButton[0];
      return {
        bgTheme: this.componentClasses,
        ctaType: this.wistiaCtaData?.ctaType,
        ctaLabel: this.wistiaCtaData?.ctaLabel || 'Watch Overview',
        wistiaId: this.wistiaCtaData?.wistiaId || '',
      };
    }
  }

  get secondButtonData(): VisionButton {
    if (this.hasSecondButtonData && this.data.videoButton[0].buttonType === 'button') {
      this.newsecondButtonData = this.data.videoButton[0];
      return {
        bgTheme: this.componentClasses,
        buttonStyle: this.newsecondButtonData?.ctaType,
        buttonText: this.newsecondButtonData?.ctaLabel || 'Start Today',
        buttonLink: this.newsecondButtonData?.ctaOtherlink,
        fullWidth: false,
        nofollow: this.ctaNofollow,
        noreferrer: this.ctaNewTab,
        noopener: this.ctaNewTab,
        newTab: this.ctaNewTab,
        ctaTag: this.ctaTag,
      };
    }
  }

  get ctaType(): string {
    return this.data.ctaType === null
      ? 'bg-ace-blue-600 hover:bg-ace-blue-500 text-vis-reverse h-12 inline-flex items-center justify-center'
      : this.data.ctaType;
  }

  get showCTA(): boolean {
    return this.product !== undefined || (this.data.ctaLink !== null && !this.data.ctaLink.includes('useModal'));
  }

  get showModal1CTA(): boolean {
    return this.data.ctaLink !== null && this.data.ctaLink.includes('useModal');
  }

  get showModal2CTA(): boolean {
    return this.secondButtonData && this.secondButtonData.buttonLink.includes('useModal');
  }

  get showPriceCta(): boolean {
    return this.data.showPricing && this.product !== undefined;
  }

  get showPricing(): boolean {
    return this.data.showPricing;
  }

  get showScroll(): boolean {
    return this.data.ctaArrowDirection !== null && !this.isLink;
  }

  get displayOfferTimer(): boolean {
    return this.data.showOfferTimer;
  }

  get ctaLabel(): string {
    return this.data.ctaLabel === null ? 'Start Today' : this.data.ctaLabel;
  }

  get ctaLink(): string {
    if (this.isLink) {
      return this.data.ctaLink;
    }
    if (
      this.data.ctaLink &&
      this.data.ctaLink !== null &&
      this.data.ctaLink.includes('#') &&
      this.data.ctaLink.includes('http://')
    ) {
      const strippedLink = this.data.ctaLink.replace('http://', '');
      return strippedLink;
    }
    return this.data.ctaLink && this.data.ctaLink !== null ? this.data.ctaLink : this.productCtaLink;
  }

  get ctaTag(): string {
    return this.data.ctaTag !== null ? this.data.ctaTag : '';
  }

  get ctaNewTab(): boolean {
    return this.data.ctaNewTab;
  }

  get checkStyle(): string {
    return this.env.get.brandConfig.checkClassName;
  }

  get ctaNofollow(): boolean {
    return this.data.ctaNofollow;
  }

  get buttonScrollArrowDirection(): string {
    return this.data.ctaArrowDirection;
  }

  get hasSecondButtonData(): boolean {
    return this.data.videoButton && this.data.videoButton.length > 0;
  }

  get categoryWidgets(): CategoryWidgets[] {
    if (this.data['categoryWidgets']?.length > 0) {
      return this.data['categoryWidgets'];
    }
  }

  get featuredContent(): FeaturedContent {
    if (
      this.data.featuredContent &&
      this.data.featuredContent.length > 0 &&
      this.data.featuredContent[0].featuredContentText !== null &&
      this.data.featuredContent[0].featuredContentIcon.length > 0
    ) {
      this.data.featuredContent[0]['backgroundColor'] =
        this.componentClasses.includes('bg-vis-dark') || this.componentClasses.includes('bg-ace-grey-900')
          ? 'light'
          : 'dark';
      return this.data.featuredContent[0];
    }
  }

  get showFirstButtonOnly(): boolean {
    return (this.showModal1CTA || this.showCTA) && !this.wistiaCtaData && !this.secondButtonData; // && this.data;
  }

  get bottomRightContent(): BottomRightContent[] {
    if (this.data.aarpBottomRightContent && this.data.aarpBottomRightContent !== null) {
      return this.data.aarpBottomRightContent.length > 0 ? this.data.aarpBottomRightContent : null;
    }
    return this.data.bottomRightContent !== null && this.data.bottomRightContent.length > 0
      ? this.data.bottomRightContent
      : null;
  }

  ngOnInit() {
    this.document.addEventListener('keydown', (event: KeyboardEvent) => this.handleKeyDown(event));
    this.componentClasses = this.data.backgroundColor;
    if (!this.componentClasses) {
      this.componentClasses = this.data.styles.join(' ');
    }
    if (this.hasSecondButtonData && this.data.videoButton[0].buttonType === 'videoButton') {
      this.wistia.insertWistiaCtaData(this.wistiaButtonData.wistiaId);
    }
    if (this.data.autoplayTransitionDelay) {
      const delayInt = parseInt(this.data.autoplayTransitionDelay);
      this.transitionTime = delayInt;
    } else {
      this.transitionTime = 6000;
    }
    this.offerEndTime = this.getOfferEndTime();
    this.countdown = this.countdownService.create(this.offerEndTime);
    if (this.data.eyebrow !== null) {
      this.hasContent = true;
      this.showTitle = true;
      this.data.eyebrow = this.redactor.bypassSanitizer(this.data.eyebrow);
    }
    if (this.data.sectionTitle !== null) {
      this.hasContent = true;
      this.showTitle = true;
      this.data.sectionTitle = this.redactor.bypassSanitizer(this.data.sectionTitle);
    }
    if (this.data.sectionContent !== null) {
      this.hasContent = true;
      this.showContent = true;
      this.data.sectionContent = this.redactor.bypassSanitizer(this.data.sectionContent, 'lazy');
    }
    if (this.data.modalContent !== null) {
      this.hasContent = true;
      this.modalHasContent = true;
      this.data.modalContent = this.redactor.bypassSanitizer(this.data.modalContent);
    }
    this.data.rightContent = this.redactor.bypassSanitizer(this.data.rightContent, 'lazy');
    if (this.data.product) {
      if (Array.isArray(this.data.product)) {
        this.data.product = this.data.product[0];
      }
      if (this.data.product !== undefined) {
        this.hasContent = true;
        this.ps.getProductData(this.data.product).then((product: XGritCompleteProduct) => {
          this.product = product;
          this.ps.setProduct(product);
          this.pricingText = this.pricing.getPricingText(this.product, this.data.pricingText);
          this.originalPrice = product.apiData.originalPrice;
          this.discountPrice = product.apiData.discountPrice;
          this.productCtaLink = product.apiData.checkoutLink;
          if (this.discountPrice && this.originalPrice > 0) {
            this.percentSaved = ((this.originalPrice - this.discountPrice) / this.originalPrice) * 100;
            this.percentSavedText = `Save ${this.percentSaved.toFixed(0)}% Today`;
          }
        });
      }
    }
    if (!this.hasContent) {
      this.hasContent =
        this.data.productSeal.length > 0 || this.data.ctaLabel?.length > 0 || this.data.ctaLink?.length > 0;
    }
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.componentClasses,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);

    if (this.data.showTrustpilotStars && this.env.get.brandConfig.trustpilotId.length > 0) {
      this.tps.getBrandStarsRating().then((trustPilotRating) => {
        this.trustpilotTrustScore = trustPilotRating.score.trustScore;
        this.trustpilotNumberOfReviews = trustPilotRating.numberOfReviews.total;
        this.checkTrustpilotStars();
      });
    }
  }

  ngAfterViewInit(): void {
    if (
      this.document.getElementsByClassName('highlighted-text-modal-1').length > 0 &&
      this.data.highlightedTextModal1.length > 0
    ) {
      this.document.getElementsByClassName('highlighted-text-modal-1')[0].addEventListener('click', () => {
        this.modalContent = this.redactor.bypassSanitizer(this.data.highlightedTextModal1[0].modalContent);
        this.setModalValues(1);
      });
    }

    if (
      this.document.getElementsByClassName('highlighted-text-modal-2').length > 0 &&
      this.data.highlightedTextModal2.length > 0
    ) {
      this.document.getElementsByClassName('highlighted-text-modal-2')[0].addEventListener('click', () => {
        this.setModalValues(2);
      });
    }

    this.onWindowScroll();
  }

  private setModalValues(index: number): void {
    if (index === 1) {
      this.modalContent = this.redactor.bypassSanitizer(this.data.highlightedTextModal1[0].modalContent);
    } else {
      this.modalContent = this.redactor.bypassSanitizer(this.data.highlightedTextModal2[0].modalContent);
    }
    this.showModal = true;
  }

  private checkTrustpilotStars(): void {
    if (this.trustpilotNumberOfReviews) {
      this.trustpilotVerified = true;
    }
  }

  private getOfferEndTime = (): number => {
    let endTime = null;
    // If we receive start and end dates for promotion, we need to calculate the range of time between them.
    if (
      this.data &&
      this.data.countdownInfo &&
      this.data.countdownInfo.promotionStartDateTime &&
      this.data.countdownInfo.promotionEndsDateTime
    ) {
      const date = new Date();
      const startDate = new Date(this.data.countdownInfo.promotionStartDateTime);
      const endDate = new Date(this.data.countdownInfo.promotionEndsDateTime);
      // If the actual date is greater than the promo start date and below the promo end date, then the timer will get the remaining time until the end date.
      if (date < endDate && date > startDate) {
        endTime = endDate.valueOf();
      }
    }
    return endTime;
  };

  toggleCourseOverviewMinimize() {
    this.isCoursePopupMinimized = !this.isCoursePopupMinimized;
  }

  toggleMobileCourseOverviewMinimize() {
    this.isMobileCoursePopupMinimized = !this.isMobileCoursePopupMinimized;
  }

  modalCtaClass(): string {
    return ['bg-vis-dark', 'bg-ace-grey-900', 'bg-ace-teal-600'].some((bg) => this.componentClasses.includes(bg))
      ? 'top-product__modal-cta-dark'
      : 'top-product__modal-cta';
  }

  ctaVideoPlay(): void {
    this.clientWistia.loadVideoScripts(this.data.videoButton[0]?.wistiaId);
    this.document.getElementById(`video-cta-spinner`).classList.remove('hidden');
    this.wistia.waitForElement(
      `#wistia-${this.data.videoButton[0]?.wistiaId}-1_popover_popover_close_button`,
      this.data.videoButton[0]?.wistiaId,
    );
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (this.topSectionCta) {
      this.elementVisibility.checkElementVisiblity(this.topSectionCta.nativeElement);
    }
  }

  activateModal(): void {
    if (this.modalHasContent) {
      this.modalContent = this.data.modalContent;
      this.showModal = true;
    }
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Enter') {
      this.showModal = false;
    }
  }

  handleBlur(id: string): void {
    if (id === 'close-modal') {
      this.document.getElementById('modal-content').focus();
    }
  }

  openTruspilot(): void {
    window.open(this.truspilotBrandUrl, '_blank');
  }
}
