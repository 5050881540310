import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import { LearnMore } from './learn-more';
import { learnModeMockData } from './learn-more-mock-data';

@Component({
  selector: 'learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss'],
})
export class LearnMoreComponent implements OnInit {
  @Input() data: LearnMore = learnModeMockData;
  theme: ThemeInterface;

  constructor(private redactor: WysiwygRedactorService, private themeParser: ThemeParserService) {}

  get buttonData(): VisionButton {
    return {
      bgTheme: this.theme.root,
      buttonLink: this.data.ctaLink,
      buttonText: this.ctaLabel,
      buttonStyle: this.data.ctaStyle,
      useIcon: this.data.ctaStyle == 'tertiary',
      newTab: this.data.ctaNewTab,
      nofollow: this.data.ctaNofollow,
      noopener: this.data.ctaNewTab,
      noreferrer: this.data.ctaNewTab,
      ctaTag: this.data.ctaTag,
      fullWidth: false,
    };
  }

  get flexLayout(): string {
    switch (this.data.layout) {
      case 'Row':
        return 'flex-col md:flex-row';
      case 'Col':
        return 'flex-col';
    }
    return 'flex-col md:flex-row';
  }

  get buttonLayout(): string {
    switch (this.data.layout) {
      case 'Row':
        if (!this.useBannerLayout) {
          return 'pt-v-spacing px-v-spacing md:mt-0 md:flex md:items-center';
        }
        return '';
      case 'Col':
        return 'px-v-spacing max-w-max';
    }
    return '';
  }

  get ctaLabel(): string {
    return this.data.ctaLabel ? this.data.ctaLabel : 'Learn More';
  }

  get componentPadding(): string {
    return this.theme.container.includes('!py') ? '' : 'py-8 md:py-12';
  }

  get contentLayout(): string {
    switch (this.data.layout) {
      case 'Row':
        return 'px-v-spacing';
      case 'Col':
        return 'py-v-spacing px-v-spacing max-w-vision mx-auto';
    }
    return 'py-v-spacing sm:px-v-spacing';
  }

  get hasImage(): boolean {
    return this.data.learnMoreImage && this.data.learnMoreImage.length > 0;
  }

  get useBannerLayout(): boolean {
    return this.hasImage && this.data.layout == 'Row';
  }

  get content(): string {
    return this.data.learnMoreContent;
  }

  ngOnInit(): void {
    this.data.learnMoreContent = this.redactor.bypassSanitizer(this.data.learnMoreContent);
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
  }
}
