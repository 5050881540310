import type { Footer } from './footer';

export const footerData: Footer = {
  aceFooterLogo: [
    {
      typeHandle: 'logo',
      logoLink: 'https://www.aceable.com/',
      logoImage: [
        {
          url: 'https://xgrit-ecom.imgix.net/v3_images/transforms/_350x89_crop_center-center_none/ace_ins_logo.svg?v=1730836120&auto=compress,format',
          altText: 'Aceable logo',
        },
      ],
    },
  ],
  aceGeneralLinkGroup: [
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceable.com/company/careers/',
      linkText: 'Careers',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceable.com/driving-partnerships/',
      linkText: 'Partnerships',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://support.aceabledriving.com/hc/en-us/',
      linkText: 'Help Center',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://support.aceabledriving.com/hc/en-us/requests/new',
      linkText: 'Contact',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceable.com/blog/',
      linkText: 'Our Blog',
      newTab: false,
      nofollow: false,
    },
  ],
  aceVerticalLinkGroup: [
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceable.com/driving/',
      linkText: 'Driving',
      newTab: false,
      nofollow: false,
    },
    {
      typeHandle: 'link',
      linkUrl: 'https://www.aceableagent.com/',
      linkText: 'Real Estate',
      newTab: false,
      nofollow: false,
    },
  ],
  aceSocialLinkGroup: [
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.tiktok.com/@aceabledriving',
      linkText: 'TikTok',
      newTab: true,
      nofollow: false,
      icon: 'tiktok',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.instagram.com/aceable/',
      linkText: 'Instagram',
      newTab: true,
      nofollow: false,
      icon: 'instagram',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://x.com/aceableagent',
      linkText: 'X',
      newTab: true,
      nofollow: false,
      icon: 'x-twitter',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.youtube.com/channel/UC9VfB1Mgu4Msm39b7kXruBw',
      linkText: 'YouTube',
      newTab: true,
      nofollow: false,
      icon: 'youtube',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.facebook.com/aceable/',
      linkText: 'Facebook',
      newTab: true,
      nofollow: false,
      icon: 'facebook-square',
    },
    {
      typeHandle: 'socialLink',
      linkUrl: 'https://www.linkedin.com/company/aceable',
      linkText: 'LinkedIn',
      newTab: true,
      nofollow: false,
      icon: 'linkedin',
    },
  ],
  bottomLinkGroup: [
    {
      typeHandle: 'link',
      linkText: 'Privacy Policy',
      linkUrl: 'https://www.aceable.com/privacy-policy/',
      nofollow: false,
      newTab: false,
    },
    {
      typeHandle: 'link',
      linkText: 'Terms & Conditions',
      linkUrl: 'https://www.aceable.com/tandc/',
      nofollow: false,
      newTab: false,
    },
  ],
  aceShareGroup: [
    {
      label: 'Partner with Us',
      link: '/driving-partnerships/',
    },
    {
      label: 'Refer a Friend',
      link: '/referral/invite/',
    },
  ],
  aceShowHideFooterNav: true,
  emailCapture: [],
};
