<div class="hidden lg:block" [class.no-menu]="showMenu === false">
  <div>
    <div class="h-2 bg-vis-brand-2 mb-1"></div>
    <div class="section-container flex items-center justify-between">
      <div class="flex items-center">
        <ng-container *ngIf="showLogoHref; else altLogo">
          <a href="/">
            <img
              [class.logo]="showMenu === false"
              class="inline-block"
              src="https://dec-mkt.imgix.net/images/logo.png?auto=compress,format"
              height="45"
              width="280"
              data-test="decLogo"
            />
            <img
              *ngIf="secondaryLogo"
              class="addl-logo inline-block pl-4"
              [src]="secondaryLogo.url"
              alt="{{ secondaryLogo.alt }}"
            />
          </a>
        </ng-container>
        <ng-template #altLogo>
          <a href="/">
            <img
              class="inline-block"
              src="https://dec-mkt.imgix.net/images/alternate-logo.png?auto=compress,format"
              alt="Alternate Logo"
            />
          </a>
        </ng-template>
      </div>
      <div class="flex items-center top-header_right">
        <a
          href="https://driversed.com/support/"
          class="text-sm text-vis-brand-2 underline mr-3"
          data-test="helpLink"
        >
          Help Center
        </a>
        <button
          class="primary-button v-button"
          data-test="loginButton"
        >
          <a href="https://app.driversed.com/user/signin/">Log In</a>
        </button>
      </div>
    </div>
    <div class="section-container relative">
      <ul class="flex justify-around" data-test="headerMenu">
        <li
          class="menu-item stateSelector flex flex-col px-0 py-4 text-base font-bold text-vis-brand-2 relative"
          data-test="stateSelector"
        >
          <div
            #currentStateToggler
            (click)="toggleMenu()"
            (keydown.enter)="onEnter()"
            tabindex="0"
            class="cursor-pointer hover:underline flex"
          >
            <span>{{ currentMenu.state }}</span>
            <span #stateArrow class="z-10">
              <img src="https://dec-mkt.imgix.net/blue_arrow_dec_header.svg?v=1736191608?auto=compress,format" 
              class="mt-1.5"
              tabindex="-1" 
              aria-label="" 
              alt=""
              />
            </span>
          </div>
        </li>
        <div
          *ngIf="stateSelectionList"
          class="z-50 main-menu__dropdown absolute w-full left-0"
          [ngClass]="{ 'main-menu__dropdown_open': menuOpen }"
        >
          <ul #statesDropdown class="flex flex-col flex-wrap px-5 py-5 bg-ace-grey-100" data-test="stateListDropdown">
            <li *ngFor="let item of stateSelectionList; let first = first; let last = last">
              <a
                tabindex="0"
                href="{{ item.href }}"
                class="{{ first ? 'first' : last ? 'last' : '' }}"
                (blur)="onBlur($event)"
                (keydown)="onKeyDown($event)"
              >
                {{ item.text }}
              </a>
            </li>
          </ul>
        </div>
        <ng-template ngFor let-group [ngForOf]="menuGroupList">
          <li
            *ngIf="group[0]"
            class="menu-item flex flex-col px-0 py-4 text-base font-bold {{ group.length > 1 && 'icon-right' }}"
            data-test="menuItems"
          >
            <a *ngIf="group[0].href" class="flex leading-6 pb-1" [ngClass]="{ underline: group[0].isCurrentItem }" href="{{ group[0].href }}">
              {{ group[0].text }}
              <span *ngIf="group.length > 1">
                <img src="https://dec-mkt.imgix.net/black-arrow-dec.svg?v=1736191607?auto=compress,format" 
                  class="mt-0.5"
                  tabindex="-1" 
                  aria-label="" 
                  alt=""
                />
              </span>
            </a>
            <span *ngIf="!group[0].href" class="flex leading-6 pb-1" [ngClass]="{ underline: group[0].isCurrentItem }">
              {{ group[0].text }}
              <span *ngIf="group.length > 1">
                <img src="https://dec-mkt.imgix.net/black-arrow-dec.svg?v=1736191607?auto=compress,format" 
                  class="mt-0.5"
                  tabindex="-1" 
                  aria-label="" 
                  alt=""
                />
              </span>
            </span>
            <ul *ngIf="group.length > 1" class="flex w-full {{ group.length > 1 && 'flex-wrap' }}">
              <ng-template ngFor let-subItem let-i="index" [ngForOf]="group.slice(1)">
                <li class="menu-sub-item font-normal text-sm leading-tight pt-px" data-test="subMenuItems">
                  <a [ngClass]="{ underline: subItem.isCurrentItem }" href="{{ subItem.href }}">{{ subItem.text }}</a>
                  <span *ngIf="i < group.length - 2" class="px-1">|</span>
                </li>
              </ng-template>
            </ul>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</div>
<dec-mobile-menu
  *ngIf="showMenu"
  class="block lg:hidden"
  [state]="data.state"
  [menuGroupList]="menuGroupList"
></dec-mobile-menu>
