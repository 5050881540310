<div *ngIf="hasContent" class="top-product font-body bg-cover {{ theme?.root }}" [style]="theme?.bgImage">
  <div class="container flex flex-col {{ theme?.container }} {{ showContent ? imgAlign : 'items-center' }}">
    <div class="lg:w-1/2 w-full flex-col py-v-spacing sm:px-v-spacing lg:mr-v-spacing">
      <div *ngIf="showTitle" class="top-product__title flex mb-v-spacing lg:mb-8">
        <img
          *ngIf="data.productSeal.length > 0 && data.productSeal[0].url !== null"
          [src]="data.productSeal[0].url"
          [alt]="getAlt('seal')"
          class="mr-v-spacing object-cover top-product__seal"
          width="80"
          height="80"
        />
        <div class="wysiwyg" [innerHTML]="data.topProductTitle"></div>
      </div>
      <div
        *ngIf="showContent"
        class="wysiwyg top-product__content w-full {{ checkStyle }} {{
          theme?.linkColor === ('text-vis-reverse' || 'text-white') ? 'darkLink' : ''
        }}"
        [innerHTML]="data.topProductContent"
      ></div>
      <div class="flex flex-col">
        <div class="md:flex-row flex-col mt-v-spacing" *ngIf="displayPrice">
          <div class="{{ displayOfferTimer ? '' : 'lg:flex-col' }} flex pricing top-product__pricing">
            <div class="flex items-center prices" [innerHtml]="pricingText"></div>
          </div>
          <div *ngIf="displayOfferTimer" class="timer flex lg:justify-start md:justify-center items-center ml-0">
            <div class="mr-2">OFFER ENDS</div>
            <div class="text-ace-pink-600 tracking-wide">
              <span class="hour">{{ countdown | async | durationText : 'daysToHours' }}:</span>
              <span class="minute">{{ countdown | async | durationText : 'minutes' }}:</span>
              <span class="second">{{ countdown | async | durationText : 'seconds' }}</span>
            </div>
          </div>
        </div>
        <div class="flex lg:flex-row flex-col items-start lg:items-center mt-v-spacing">
          <div *ngIf="!!ctaLabel" #topProductCta>
            <vision-button defaultStyle="primary" [data]="topProductButtonData"></vision-button>
          </div>

          <vision-button *ngIf="!!buttonScrollLabel" defaultStyle="primary" [data]="buttonScrollData"></vision-button>

          <button
            *ngIf="!data.hideGuaranteeLink"
            (click)="activateModal()"
            class="top-product__modal-cta {{
              moneyGuaranteeLinkStyle
            }} flex justify-center font-bold mt-v-spacing lg:mt-0 lg:ml-v-spacing"
            [class.cursor-pointer]="data.modalContent"
          >
            {{ moneyGuaranteeLabel }}
          </button>
        </div>
      </div>
    </div>
    <div
      class="lg:w-1/2 w-full flex flex-col items-center mx-auto justify-center"
      [ngClass]="{
        'py-v-spacing sm:px-v-spacing':
          data.topBundleTopRightContent || data.topBundleBottomRightContent || data.topProductWistiaVideoId
      }"
    >
      <div
        *ngIf="data.topBundleTopRightContent"
        class="wysiwyg table w-full mb-v-spacing"
        [innerHTML]="data.topBundleTopRightContent"
      ></div>
      <div
        *ngIf="data.topBundleBottomRightContent"
        class="wysiwyg noborder w-full text-sm"
        [innerHTML]="data.topBundleBottomRightContent"
      ></div>
      <img
        class="top-product__img w-full lg:order-none order-last"
        *ngIf="
          data.topProductImage &&
          data.topProductImage.length > 0 &&
          data.topProductImage[0].url !== null &&
          !data.topProductWistiaVideoId
        "
        [src]="imgixService.buildImgixUrl(data.topProductImage[0].url)"
        [alt]="getAlt('prod')"
        width="370"
        height="242"
        preloadImg
      />
      <div *ngIf="data.topProductWistiaVideoId" class="wysiwyg h-auto w-full" [innerHTML]="wistiaEmbed"></div>
    </div>
  </div>

  <div
    *ngIf="showModal"
    class="top-product__modal fixed inset-0 bg-vis-dark/50 z-50 flex justify-center items-center"
  >
    <div class="top-product__modal-body bg-white text-vis-regular shadow-md p-v-spacing wysiwyg relative rounded flex">
      <div 
        class="top-product__modal-body-content" 
        [innerHTML]="data.modalContent"
        id="modal-content"
        aria-label="Modal Content"
        tabindex="{{ showModal ? 0 : -1 }}"
      ></div>
      <fa-icon
        id="close-modal"
        aria-label="Close Modal"
        tabindex="{{ showModal ? 0 : -1 }}"
        (blur)="handleBlur($event.currentTarget.id)"
        class="top-product__modal-close text-vis-muted absolute right-v-spacing top-v-spacing fa-1x cursor-pointer"
        (click)="showModal = false"
        [icon]="faTimes"
      ></fa-icon>
    </div>
  </div>
</div>
<div
  *ngIf="!ctaDisplay && displayPrice"
  cond-class
  [target]="'body'"
  [addClass]="'body-margin'"
  class="w-full fixed bottom-0 bg-white z-50"
>
  <div
    class="top-product__bottom-banner py-v-spacing mx-v-spacing md:ml-auto flex flex-row items-center justify-between md:justify-end"
  >
    <div class="flex flex-row">
      <div
        class="text-lg md:text-xl text-vis-regular decoration-ace-grey-900 font-extrabold pr-1 xs:pr-2 line-through"
        *ngIf="discountPrice < originalPrice"
      >
        ${{ originalPrice }}
      </div>
      <div
        class="text-lg md:text-xl text-ace-tela-900 font-extrabold pr-1 xs:pr-2"
        [ngClass]="{ 'md:pr-v-spacing': discountPrice >= originalPrice }"
      >
        ${{ discountPrice }}
      </div>
    </div>

    <vision-button defaultStyle="primary" [data]="topProductButtonData"></vision-button>
  </div>
</div>
