/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const LearnMoreFragment = {
  LearnMore: gql`
    fragment LearnMoreQuery on baseComponents_learnMore_BlockType {
      backgroundImage {
        url
        ... on images_Asset {
          altText
        }
      }
      ctaLabel
      ctaLink
      ctaNewTab
      ctaNofollow
      ctaStyle
      layout
      learnMoreContent
      learnMoreImage {
        url
        ... on images_Asset {
          altText
        }
      }
      styles
      typeHandle
    }
  `,
};
