import type { BrandConfig } from '../../app/interfaces/brand-config';
import { iconConfig } from './iconConfig';

export const brandConfig: BrandConfig = {
  id: 'INS',
  name: 'Aceable Insurance',
  appName: 'AceableInsurance',
  url: 'www.insurance.aceable.com',
  hostName: 'insurance.aceable.com',
  helpPage: '',
  cdiParamKey: '',
  vertical: 'insurance',
  slugPrefix: '',
  craftSite: 'ins',
  productHandler: '',
  bannerHandler: '',
  cdiParamValueWhenEnabled: '',
  trustpilotId: '',
  trustpilotApiKey: '',
  iterableGetApiKey: '',
  iterableApiKey: '',
  ambassadorGroupId: '',
  pricingText: '',
  altPricingText: '',
  productCategoryHandle: '',
  iconConfig: iconConfig,
  checkClassName: '',
  useLocationCookie: false,
  ctaText: 'Get My License',
  rsaImageFooterText: '',
  hubspotID: '',
};
